import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { invert } from "lodash";
import useFormSubmission from "../hooks/useFormSubmission";
import { pendingFormExists } from "../queries";
import { FORM_TYPE_HTML_FRIENDLY } from "../../util/constants";

export default function CommitteeAndExamPetition(props = {}) {
  const { Component, match = {} } = props;
  const { params = {}, pathname = "" } = match;
  const { formType: formTypeFriendly } = params;
  const formType = invert(FORM_TYPE_HTML_FRIENDLY)[formTypeFriendly];
  const isCreate = pathname.includes("/create");
  
  const user = useSelector((state) => (state?.authentication?.user ?? {}));
  const { academicPlan = null } = user;
  const osuid = (params?.osuid ?? user.osuid);
  const { navigateToFormLandingPage } = useFormSubmission({ osuid });
  const [hasValidated, setValidated] = useState(false);
  const [isValidating, setValidating] = useState(true);

  const validateCreate = useCallback(async () => {
    if(!academicPlan) return navigateToFormLandingPage(formTypeFriendly, "Unable to find a valid academic plan. If you are a current student, please contact your graduate office.");
  }, [academicPlan, formTypeFriendly, navigateToFormLandingPage]);

  useEffect(() => {
    if(isCreate && hasValidated === false && isValidating === true) {
      validateCreate();
      setValidated(true);
      setValidating(false);
    } else {
      setValidating(false);
    }
  }, [hasValidated, isCreate, isValidating, validateCreate]);

  return (<Component {...props} isValidating={isValidating} />);
}