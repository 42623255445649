
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Mui } from "@osu/react-ui";
import Questions from "../../../Common/components/Questions.js";
import { gradChairApprovalQuestions } from "../../../Forms/data/ApplicationForProfessionalExam.js";
import { ACTION_STATUS, APPROVAL_STATUS, CATEGORY_M, ERROR_MESSAGE, EVENT_APPROVE_APPROVAL_FORM, EVENT_SAVE_APPROVAL_FORM, WorkflowApprovalTask } from "../../../util/constants.js";

export default function ApplicationForProfessionalExam(props = {}) {
    const { approve, approvalTask, form, formActionStatus, initialized, save, setInitialized } = props;
    const [questionValues, questionDispatch] = useReducer(questionReducer, {});
    const [questions, setQuestions] = useState([]);

    const buildApprovalForm = (q, qv) => {
        const approvalForm = {};
        for(const question of q) {
            const { dataField, id } = question;
            const dataFields = dataField.split(".");
            const formField = (dataFields.length > 1 ? dataFields[dataFields.length - 1] : dataField);
            const questionValue = qv[id].value;
            const value = (questionValue?.value ?? questionValue);
            approvalForm[formField] = value;
        }
        return approvalForm;
    };
    const buildQuestionValues = useCallback((questions) => {
        return questions.reduce((acc, question) => {
            const field = question.dataField;
            const fields = field.split(".");
            let value = null;
            if(fields.length > 1) {
                for(const f of fields) {
                    value = (value === null ? form[f] : value[f]);
                }
            } else {
                value = form[field];
            }
            acc[question.id] = { value };
            return acc;
        }, {});
    }, [form]);
    const hasCategoryMFaculty = (form = {}) => {
        const { committee = {} } = form;
        if(committee?.advisor && committee.advisor.category === CATEGORY_M) return true;
        if(committee?.coAdvisor && committee.coAdvisor.category === CATEGORY_M) return true;
        if(committee?.members?.length) {
            for(const member of committee.members) {
                if(member.category === CATEGORY_M) return true;
            }
        }
        return false;
    };
    const validate = (f, q, qv) => {
        let isValid = true;
        for(const question of q) {
            const questionValue = qv[question.id];
            let error = null;
            if(question.validation) {
                error = question.validation(f, questionValue.value);
                if(error) isValid = false;
            } else if(question.required === true) {
                if(!questionValue?.value) error = ERROR_MESSAGE.REQUIRED;
                if(error) isValid = false;
            }
            questionDispatch({ type: "UPDATE", payload: { error, field: question.id, value: questionValue.value } });
        }
        return isValid;
    };
    const onSave = useCallback(() => {
        const approvalForm = buildApprovalForm(questions, questionValues);
        save(approvalForm);
    }, [save, questions, questionValues]);
    const onApprove = useCallback(() => {
        const isValid = validate(form, questions, questionValues);
        if(isValid) {
            const approvalForm = buildApprovalForm(questions, questionValues);
            approve(approvalForm);
        }
    }, [approve, form, questions, questionValues]);

    // initialize approval form (if necessary)
    useEffect(() => {
        if(initialized === false && form && form.status === APPROVAL_STATUS.PENDING) {
            let approvalFormQuestions = null;
            // Grad Chair Approval
            if(approvalTask === WorkflowApprovalTask.GRAD_CHAIR_COORDINATOR_APPROVAL && hasCategoryMFaculty(form)) {
                approvalFormQuestions = gradChairApprovalQuestions;
            }
            if(approvalFormQuestions) {
                setQuestions(approvalFormQuestions);
                questionDispatch({ type: "INITIALIZE", payload: buildQuestionValues(approvalFormQuestions) });
                setInitialized(true);
            }
        }
    }, [approvalTask, buildQuestionValues, form, initialized, setInitialized]);

    // add listener for save approval form event
    useEffect(() => {
        document.addEventListener(EVENT_SAVE_APPROVAL_FORM, onSave);
        return () => {
            document.removeEventListener(EVENT_SAVE_APPROVAL_FORM, onSave);
        };
    }, [onSave]);

    // add listener for approve approval form event
    useEffect(() => {
        document.addEventListener(EVENT_APPROVE_APPROVAL_FORM, onApprove);
        return () => {
            document.removeEventListener(EVENT_APPROVE_APPROVAL_FORM, onApprove);
        };
    }, [onApprove]);

    if(initialized === true && questions.length > 0) {
        return (
            <Mui.Box display={(formActionStatus === ACTION_STATUS.LOADING ? "none" : "block")} paddingTop="1rem">
                <Mui.Typography component="h3" variant="h4" className="sr-only">Approval Form</Mui.Typography>
                <Mui.Box maxWidth="35rem">
                    <Questions questions={questions} dispatchStepState={questionDispatch} values={questionValues} />
                </Mui.Box>
            </Mui.Box>
        );
    }

    return null;
}

function questionReducer(state, action) {
    const { payload, type } = action;
    switch(type) {
        case "INITIALIZE":
            return payload;
        case "UPDATE": {
            const { error = null, field, value } = payload;
            const fieldState = state[field];
            return { ...state, [field]: { ...fieldState, error, value } };
        }
        default:
            return state
    }
}