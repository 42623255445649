
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Mui } from "@osu/react-ui";
import Questions from "../../../Common/components/Questions.js";
import { gradSchoolPreApprovalQuestions } from "../../../Forms/data/CombinedDegreeProgram.js";
import { ACTION_STATUS, APPROVAL_STATUS, ERROR_MESSAGE, EVENT_APPROVE_APPROVAL_FORM, EVENT_SAVE_APPROVAL_FORM, WorkflowApprovalTask } from "../../../util/constants.js";
import { inputMappings } from "../../../util/enums";

export default function CombinedDegreeProgram(props = {}) {
    const { approve, approvalTask, form, formActionStatus, initialized, save, setInitialized } = props;
    const [questionValues, questionDispatch] = useReducer(questionReducer, {});
    const [questions, setQuestions] = useState([]);

    const buildApprovalForm = (q, qv) => {
        const approvalForm = {};
        for(const question of q) {
            const { dataField, id, type } = question;
            const dataFields = dataField.split(".");
            const formField = (dataFields.length > 1 ? dataFields[dataFields.length - 1] : dataField);
            const questionValue = qv[id].value;
            if(type === inputMappings.inputGroup) {
                const values = [];
                for(const nestedQuestion of question.questions) {
                    for(const value of questionValue) {
                        const nestedValue = value[nestedQuestion.id];
                        if(nestedValue) {
                            if(nestedQuestion.type === inputMappings.nameNLookup) {
                                if(!!nestedValue.nameN && !!nestedValue.emplid) {
                                    values.push(nestedValue);
                                }
                            } else {
                                values.push(nestedValue);
                            }
                        }
                    }
                }
                approvalForm[formField] = values;
            } else {
                const value = (questionValue?.value ?? questionValue);
                approvalForm[formField] = value;
            }
        }
        return approvalForm;
    };
    const buildQuestionValues = useCallback((questions) => {
        return questions.reduce((acc, question) => {
            const field = question.dataField;
            const fields = field.split(".");
            let value = null;
            if(fields.length > 1) {
                for(const f of fields) {
                    value = ((value === null ? form[f] : value[f]) ?? null);
                }
            } else {
                value = form[field];
            }
            if(question.type === inputMappings.inputGroup) {
                const inputGroupValueArray = [];
                const dataFieldMapping = {};
                const inputGroupValues = (Array.isArray(value) ? value : [value]);
                question.questions.forEach((inputGroupQuestion, inputGroupQuestionIndex) => {
                    let inputGroupValueIndex = 0;
                    const inputGroupValueItem = {};
                    for(let inputGroupValue of inputGroupValues) {
                        if(inputGroupQuestion.id) dataFieldMapping[inputGroupQuestion.id] = inputGroupQuestion.dataField;
                        if(inputGroupQuestion.dataField) inputGroupValue = inputGroupValue[inputGroupQuestion.dataField];
                        inputGroupValueItem.required = inputGroupQuestion.required;
                        inputGroupValueItem.id = `${inputGroupQuestion?.id}-choice-${inputGroupValueIndex}${inputGroupQuestionIndex}`;
                        inputGroupValueItem.initial = false;
                        if(!inputGroupValueArray[inputGroupValueIndex]) inputGroupValueArray[inputGroupValueIndex] = {};
                        inputGroupValueItem[inputGroupQuestion.id] = inputGroupValue;
                        inputGroupValueItem.value = inputGroupValue;
                        inputGroupValueArray[inputGroupValueIndex] = {
                            ...(inputGroupValueArray[inputGroupValueIndex] ?? {}),
                            ...(inputGroupValueItem ?? {})
                        }
                        inputGroupValueIndex++;
                    }
                });
                acc[question.id] = { value: inputGroupValueArray.map((item) => ({...item, dataFieldMapping })) };
            } else {
                acc[question.id] = { value };
            }
            return acc;
        }, {});
    }, [form]);
    const validate = (f, q, qv) => {
        let isValid = true;
        for(const question of q) {
            const questionValue = qv[question.id];
            let error = null;
            if(question.required === true) {
                if(question.type === inputMappings.inputGroup) {
                    const values = [];
                    for(const nestedQuestion of question.questions) {
                        for(const value of (questionValue?.value ?? [])) {
                            const nestedValue = value[nestedQuestion.id];
                            if(nestedValue) {
                                if(nestedQuestion.type === inputMappings.nameNLookup) {
                                    if(!!nestedValue.nameN && !!nestedValue.emplid) {
                                        values.push(nestedValue);
                                    }
                                } else {
                                    values.push(nestedValue);
                                }
                            }
                        }
                    }
                    if(values.length === 0) error = ERROR_MESSAGE.REQUIRED;
                } else {
                    if(!questionValue?.value) error = ERROR_MESSAGE.REQUIRED;
                }
                if(error) isValid = false;
            }
            questionDispatch({ type: "UPDATE", payload: { error, field: question.id, value: questionValue.value } });
        }
        return isValid;
    };
    const onSave = useCallback(() => {
        const approvalForm = buildApprovalForm(questions, questionValues);
        save(approvalForm);
    }, [save, questions, questionValues]);
    const onApprove = useCallback(() => {
        const isValid = validate(form, questions, questionValues);
        if(isValid) {
            const approvalForm = buildApprovalForm(questions, questionValues);
            const committeeApprovers = approvalForm.approvers.map((approver) => (approver.emplid));
            approve(approvalForm, committeeApprovers);
        }
    }, [approve, form, questions, questionValues]);

    // initialize approval form (if necessary)
    useEffect(() => {
        if(initialized === false && form && form.status === APPROVAL_STATUS.PENDING) {
            let approvalFormQuestions = null;
            // Grad School Pre Approval
            if(approvalTask === WorkflowApprovalTask.GRADSCHOOL_PRE_APPROVAL) approvalFormQuestions = gradSchoolPreApprovalQuestions;
            if(approvalFormQuestions) {
                setQuestions(approvalFormQuestions);
                questionDispatch({ type: "INITIALIZE", payload: buildQuestionValues(approvalFormQuestions) });
                setInitialized(true);
            }
        }
    }, [approvalTask, buildQuestionValues, form, initialized, setInitialized]);

    // add listener for save approval form event
    useEffect(() => {
        document.addEventListener(EVENT_SAVE_APPROVAL_FORM, onSave);
        return () => {
            document.removeEventListener(EVENT_SAVE_APPROVAL_FORM, onSave);
        };
    }, [onSave]);

    // add listener for approve approval form event
    useEffect(() => {
        document.addEventListener(EVENT_APPROVE_APPROVAL_FORM, onApprove);
        return () => {
            document.removeEventListener(EVENT_APPROVE_APPROVAL_FORM, onApprove);
        };
    }, [onApprove]);

    if(initialized === true && questions.length > 0) {
        return (
            <Mui.Box display={(formActionStatus === ACTION_STATUS.LOADING ? "none" : "block")} paddingTop="1rem">
                <Mui.Typography component="h3" variant="h4" className="sr-only">Approval Form</Mui.Typography>
                <Mui.Box display="flex" flexDirection="column">
                    <Questions questions={questions} dispatchStepState={questionDispatch} values={questionValues} />
                </Mui.Box>
            </Mui.Box>
        );
    }

    return null;
}

function questionReducer(state, action) {
    const { payload, type } = action;
    switch(type) {
        case "INITIALIZE":
            return payload;
        case "UPDATE": {
            const { error = null, field, value } = payload;
            const fieldState = state[field];
            return { ...state, [field]: { ...fieldState, error, value } };
        }
        default:
            return state
    }
}